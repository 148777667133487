body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.title-blocks-items {
  border-radius: 18px;
  box-shadow: 1px 1px 6px 2px #686868f5;
  height: 400px;
  position: relative;
}
a{
  text-decoration: none;
}
a .sahara-base {
  color: #fff;
  font-size: 23px;
  font-weight: 200;
  position: relative;
  text-align: center;
  text-decoration: none;
}
.sahara-base {
  align-items: center;
  border-radius: 18px;
  display: flex;
  height: 400px;
  justify-content: center;
  position: relative;
  transition: all 4s ease;
}
.inner-grad-layer {
  background: linear-gradient(145deg, #0003, #0006 25%, #a9007d99 50%, #e5580599 75%, #39950099);
  background-repeat: no-repeat;
  background-size: 400% 400%;
  border-radius: 18px;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: 2s;
  width: 100%;
}
.base-hitting{
  opacity: 1;
  transform-origin: 50% 50% 0px;
  transform: none;
  margin-top: 30px;
}
a .sahara-base:hover .inner-grad-layer {
  background-position: 100% 100%;

}
a .label-title {
  transform: translateY(70px) translateZ(0px);
  color:#fff;
  text-align: center;
  font-size: 20px;
  font-weight: 100;
  text-transform: uppercase;
}
.label-year {
  bottom: 15%;
  display: block;
  font-size: 38px;
  font-weight: 800;
  left: 35%;
  position: absolute;
  color: #fff;
}
.btn-orange {
  background: #e58c1e;
  color: #fff;
}
.btn-full {
  width: 100%;
}
.modal-diagram {
  border: 1px solid #f7f7f7;
}
.mgrite {
  margin-right: 10px;
}
.phase-section {
  padding: 30px 0px;
}
.img-special {
  cursor: pointer;
  object-fit: cover;
  object-position: center;
  width: 100%;
}
.pic-box {
  margin-bottom: 22px;
  padding: 0 10px;
  position: relative;
}
.btn-dwnoverlay {
  position: absolute;
  left: 30px;
  top:10px;
  opacity: 0.4;
  font-size: 10px;
}
.btn-dwnoverlay:hover
{
  opacity: 0.9;
}
img.proc {
  position: fixed;
  top: 10%;
  width: 200px;
  left: 45%;
}

/*Modal View */
.modal-overlay{
  align-items: center;
  background-color: #000000c9;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transform: scale(0);
  transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 999;
}
.modal-overlay.open {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}
.model-image img {
  box-sizing: border-box;
  display: block;
  height: 100vh;
  line-height: 0;
  margin: 0 auto;
  padding: 20px 0;
}
.full-lay {
  position: relative;
}
.btnCloser {
  position: fixed;
  background: #e37300;
  z-index: 1000000;
  top: 10px;
  left: 10px;
}
img.img-scripter {
  width: 70px;
  margin-bottom: 15px;
}
.mgleft10{
  margin-left: 10px;
}
.countBox {
  background: #00c13b;
  color: #FFF;
  padding: 10px 20px;
  position: absolute;
  right: 10px;
  font-weight: 500;
  border-radius: 13px;
}
.fill-images {
  padding: 15px;
}

