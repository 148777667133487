.nav-bar {
    background-color: #ffd689;
    background-position: 50%;
    background-size: cover;
    min-height: 400px;
    padding: 10px 40px;
}
img.cont-logo{
    width: 350px;
}
.sp-conte-work {
    font-size: 19px;
    padding-top: 30px;
    color: #fff;
}
.content-nav {
    display: flex;
    justify-content: space-between;
}
.modal-narative {
    background: #fff;
    width: 50%;
    margin: 10px 25%;
    position: fixed;
    z-index: 10000;
}
.form-group.frg {
    margin-bottom: 20px;
    margin-top: 10px;
}
.strip-bann {
    display: flex;
    padding: 10px;
}
.stitle {
    color: #fff;
    padding-right: 15px;
    font-weight: 700;
    letter-spacing: 1px;
}

@media only screen and (max-width: 600px) {
.content-nav {
    display: block;
  }
  img.cont-logo {
      width: 260px;
  }
  .sp-conte-work {
      padding: 10px;
  }
}